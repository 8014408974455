import React from 'react'
import { Helmet } from 'react-helmet'
import DefaultLayout from '../layouts/default'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api'
import styled from 'styled-components'
import logo from '../images/logo-with-text.svg'
import Footer from '../components/footer/footer'
import Header from '../components/header/header'
import GoogleMapReact from 'google-map-react';

const ContactInfo = styled.div`
`

const ContactTitle = styled.div`
    color: #1F3F68;
    font-size: 24px;
`
const ContactSubTitle = styled.a`
    color: #1F3F68;
    font-size: 20px;
`

const MarkerDescription = styled.div`
    width: 200px;
    height: 45px;
    background: white;
    margin-top: -20px;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    color: #1F3F68;
`;

const containerStyle = {
  width: '680px',
  height: '600px'
}

const center = {
  lat: -33.877202,
  lng: 151.207780
}

const isClient = typeof window !== 'undefined';

const About = ({path}) => {
  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   googleMapsApiKey: 'AIzaSyDzOMWDXJ38FngZ-2KhUeKL5B9dcWsFwos'
  // })
  // const [map, setMap] = React.useState(null)

  // const onLoad = React.useCallback(function callback (map) {
  //   const bounds = new window.google.maps.LatLngBounds()
  //   map.fitBounds(bounds)
  //   setMap(map)
  // }, [])

  // const onUnmount = React.useCallback(function callback (map) {
  //   setMap(null)
  // }, [])
  console.log(center.lat, center.lng);
  return <DefaultLayout path={path}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Contact Us - CY Finance</title>
        </Helmet>
        <Header index={4} />
        <div className='flex gap-20 justify-center py-20 px-4'>
            <div style={containerStyle}>
            { isClient && (
              <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyDzOMWDXJ38FngZ-2KhUeKL5B9dcWsFwos' }}
                defaultCenter={center}
                defaultZoom={17}
              >
                <div
                  className="marker"
                  lat={center.lat}
                  lng={center.lng}
                  text={'CY Finance'}
                  style={{
                    color: 'white', 
                    padding: '15px 10px',
                    display: 'inline-flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '100%',
                    transform: 'translate(-50%, -50%)'
                  }}
                >
                  <svg className="h-14 w-14" style={{marginTop: '-50px'}} viewBox="0 0 20 20" fill="#BE996A">
                    <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                  </svg>
                </div>
              </GoogleMapReact>
            )}
            </div>
            <ContactInfo className="flex flex-col gap-4 justify-center">
                <img src={logo} alt='logo' width={300} height={300} className='mb-4' />
                <ContactTitle>Suite 703, 370 Pitt Street, Sydney NSW 2000</ContactTitle>
                <ContactSubTitle>Phone: 02 8541 4875</ContactSubTitle>
                <ContactSubTitle className='underline' href="mailto:admin@cyfinance.com.au">admin@cyfinance.com.au</ContactSubTitle>
            </ContactInfo>
        </div>
        <Footer index={4} />
    </DefaultLayout>
}

export default About
